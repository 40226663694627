import Aura from '@primevue/themes/aura'
// @ts-ignore
import { definePreset } from '@primevue/themes'

export default definePreset(Aura, {
  primitive: {
    indigo: {
      50: '#E6F2FA',
      100: '#BDDFF2',
      200: '#94CBE9',
      300: '#6BB7E0',
      400: '#4EA8D8',
      500: '#3185B5',
      600: '#2C77A3',
      700: '#246387',
      800: '#1C4F6A',
      900: '#143B4E',
      950: '#143B4E',
    },
  },
  semantic: {
    primary: {
      50: '{indigo.50}',
      100: '{indigo.100}',
      200: '{indigo.200}',
      300: '{indigo.300}',
      400: '{indigo.400}',
      500: '{indigo.500}',
      600: '{indigo.600}',
      700: '{indigo.700}',
      800: '{indigo.800}',
      900: '{indigo.900}',
      950: '{indigo.950}',
    },
    focusRing: {
      color: '{primary.500}',
    },
  },
})
